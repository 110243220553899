<template>
  <div>
    <el-dialog
      class="upload-photo-container"
      title="上传照片/视频"
      append-to-body
      width="740px"
      top="10vh"
      :close-on-click-modal="false"
      :visible.sync="showState"
      :before-close="hidePopups"
    >
      <el-form ref="form" size="mini" :model="form" :rules="rules" label-width="100px">
        <el-form-item prop="baseProductType" label="类型：">
          <el-select v-model="form.baseProductType" :disabled="type == 1" placeholder="请选择类型" style="width: 150px" @change="typeChange">
            <el-option v-for="(item, i) in typeList" :key="i" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.baseProductType == 15 || form.baseProductType == 14" prop="videoList" label="客户视频：">
          <el-upload
            multiple
            action=""
            list-type="picture-card"
            :http-request="fileVideoChange"
            :on-change="fileVideoChange2"
            accept=".wmv, .rmvb, .mp4, .avi, .3gp,.mkv, .flv, .rm"
            :file-list="fileVideoList"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <div v-if="file.p != null">
                <el-progress type="circle" :percentage="fileVideoList.find((e) => e.uid == file.uid).p" :show-text="true"></el-progress>
              </div>
              <div v-else>
                <el-image class="el-upload-list__item-thumbnail" style="width: 146px; height: 146px" :src="file.url" fit="cover">
                  <div slot="error"></div>
                </el-image>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-delete" @click="deleteVideoFile(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="toPlayVideo(file)">
                    <i class="el-icon-caret-right"></i>
                  </span>
                </span>
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item v-else prop="pics" label="客户照片：">
          <div class="upload-content">
            <el-upload
              ref="upload"
              action="#"
              class="upload-file"
              accept="image/*"
              :show-file-list="false"
              :multiple="uploadMultiple == 1 ? false : true"
              :limit="uploadMultiple"
              :file-list="form.pics"
              :disabled="submitLoadingStatus || (uploadMultiple == 1 && form.pics.length == uploadMultiple ? true : false)"
              :http-request="handleupload"
              drag
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                <span>将图片拖到此处，或</span>
                <em>点击选择图片</em>
              </div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <u-list v-if="form.pics.length" :list="form.pics" :submit-loading-status="submitLoadingStatus" />

      <span slot="footer" class="dialog-footer">
        <!-- <el-checkbox size="mini" v-model="isChecked" :true-label="1" :false-label="0">提醒客户照片/视频已上传</el-checkbox> -->
        <span></span>
        <span>
          <el-button size="mini" @click="hidePopups">取 消</el-button>
          <el-button type="primary" class="btn-theme-color" size="mini" :loading="submitLoadingStatus" @click="submitForm">
            {{ submitLoadingStatus ? '正在上传中...' : '上传' }}
          </el-button>
        </span>
      </span>
    </el-dialog>
    <my-video :visible="videoVisible" :url="videoUrl" @cancel="videoVisible = false" />
  </div>
</template>

<script>
import { getSts } from '@/api/common'
import { uploadImg } from '@/api/order'
import uList from '@/components/UploadFile/uList'
import { mapGetters } from 'vuex'
import OSS from 'ali-oss'
import store from '@/store'
import dayjs from 'dayjs'
import myVideo from '@/components/video/video.vue'

export default {
  components: {
    uList,
    myVideo,
  },
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      submitLoadingStatus: false,
      uploadMultiple: 99999,
      isChecked: 0,
      form: {
        baseProductType: 1,
        pics: [],
        videoList: [],
      },
      rules: {
        baseProductType: [{ required: true, message: '请选择类型', trigger: 'change' }],
        pics: [{ type: 'array', required: true, message: '请上传照片', trigger: 'blur' }],
        videoList: [{ type: 'array', required: true, message: '请上传视频', trigger: 'blur' }],
      },
      ossClient: null,
      photoList: [],
      errNum: 0,
      fileVideoList: [],
      videoList: [],
      videoVisible: false,
      videoUrl: '',
      typeList: [
        { name: '原片', val: 1 },
        { name: '初修', val: 5 },
        { name: '精修', val: 6 },
        { name: '设计版面', val: 7 },
        { name: '原视频', val: 15 },
        { name: '剪辑视频', val: 14 },
      ],
      allPromise: null,
      cancel: false,
    }
  },
  computed: {
    ...mapGetters(['client']),
  },
  watch: {
    showState(val) {
      if (val) {
        this.isChecked = 0
      }
    },
    'form.pics': {
      deep: true,
      handler() {
        this.$refs.form.clearValidate('pics')
      },
    },
    //   'currentData.orderServiceId': {
    //   deep: true,
    //   handler() {
    //   if (this.currentData==) {

    //   }
    //   },
    // },
  },
  async mounted() {
    this.form.baseProductType = this.currentData.baseProductType || 1

    try {
      await store.dispatch('aliOss/getClient')
    } catch (e) {
      this.$message.error('OSS初始化失败，请刷新页面后再试')
    }
    const { accessKeyId, accessKeySecret, securityToken } = this.client
    this.ossClient = new OSS({
      region: 'oss-cn-chengdu',
      secure: true,
      accessKeyId,
      accessKeySecret,
      stsToken: securityToken,
      refreshSTSToken: async () => {
        // 向您搭建的STS服务获取临时访问凭证。
        const info = await getSts()
        // console.log(info, 'info');
        return {
          accessKeyId: info.accessKeyId,
          accessKeySecret: info.accessKeySecret,
          stsToken: info.securityToken,
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 30000000,
      // 填写Bucket名称。
      bucket: 'dapianlaile',
    })
  },
  methods: {
    typeChange() {
      this.form.pics = []
      this.fileVideoList = []
      this.form.videoList = []
    },
    async ossUpload(filename, file, uid) {
      const _this = this
      const date = dayjs().format('YYYY-MM-DD')
      let code = ''
      for (let i = 0; i < 6; i++) {
        code += parseInt(Math.random() * 10)
      }
      const VideoFilename = '/APPvideo/' + date + '/' + code + '_' + new Date().getTime() + '.' + filename.split('.')[1]
      try {
        const headers = {
          'x-oss-object-acl': 'public-read',
          'Cache-Control': 'no-cache',
        }
        const result = await _this.ossClient.multipartUpload(VideoFilename, file, {
          progress: async function (p) {
            const obj = _this.fileVideoList.find((e) => e.uid === file.uid)
            if (p < 1) {
              obj.p = Math.floor(p * 100)
            } else {
              obj.p = null
            }
            _this.$forceUpdate()
          },
          headers,
        })
        const url = result.res.requestUrls[0].split('?')[0]
        const imgUrl = url + '?x-oss-process=video/snapshot,t_1000,m_fast'
        const urlData = await this.imageUrlToBase64(imgUrl)
        const imgFilename = '/APPvideocover/' + date + '/' + code + '_' + new Date().getTime() + '.jpeg'
        const _file = this.base64ToFile(urlData, imgFilename)
        const imgHeader = { 'x-oss-object-acl': 'public-read', 'Cache-Control': 'no-cache' }
        const imgResult = await _this.ossClient.put(imgFilename, _file, { headers: imgHeader })
        this.fileVideoList.forEach((e) => {
          if (e.uid === uid) {
            e.url = imgResult.url
          }
        })
        this.form.videoList.push({
          url,
          photoName: filename,
          fileType: 2,
          coverUrl: imgResult.url,
        })
      } catch (e) {
        console.log(e)
      }
    },
    deleteVideoFile(file) {
      const index = this.fileVideoList.findIndex((el) => el.uid === file.uid)
      if (index > -1) this.fileVideoList.splice(index, 1)
      if (index > -1) this.form.videoList.splice(index, 1)
    },
    toPlayVideo(file) {
      const index = this.fileVideoList.findIndex((el) => el.uid === file.uid)
      this.videoVisible = true
      this.videoUrl = this.form.videoList[index].url
    },
    fileVideoChange(file) {
      const _this = this
      const filename = file.file.name
      _this.ossUpload(filename, file.file, file.file.uid)
    },
    fileVideoChange2(file, fileList) {
      this.fileVideoList = fileList
    },
    async handleupload(file) {
      this.form.pics = [...this.form.pics, file]
    },
    async ossUploadImg(filename, file) {
      return new Promise((resolve, reject) => {
        const date = dayjs().format('YYYY-MM-DD')
        let code = ''
        for (let i = 0; i < 6; i++) {
          code += parseInt(Math.random() * 10)
        }
        const imgFilename = '/APPimage/' + date + '/' + code + '_' + new Date().getTime() + '.jpeg'
        this.ossClient
          .put(imgFilename, file, {
            headers: { 'x-oss-object-acl': 'public-read', 'Cache-Control': 'no-cache' },
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submitForm() {
      this.cancel = false
      this.$refs.form.validate(async (valid) => {
        const formData = { ...this.form }
        formData.baseServiceType = this.currentData.baseServiceType
        formData.serviceItemId = this.currentData.serviceItemId
        // formData.isRemind = this.isChecked;
        formData.serviceId = this.currentData.serviceId

        delete formData.videoList
        if (valid) {
          const baseProductType = this.form.baseProductType
          if (baseProductType === 14 || baseProductType === 15) {
            formData.pics = [...this.form.videoList]
          } else {
            this.errNum = 0
            this.submitLoadingStatus = true
            await Promise.all(
              formData.pics.map(async (file) => {
                await this.ossUploadImg(file.file.name, file.file)
                  .then((res) => {
                    this.photoList.push({
                      url: res.res.requestUrls[0],
                      coverUrl: res.res.requestUrls[0],
                      photoName: res.name,
                      fileType: 1,
                    })
                  })
                  .catch(() => {
                    this.errNum++
                  })
              }),
            )
            formData.pics = [...this.photoList]
          }
          if (this.cancel) return
          uploadImg({ data: formData })
            .then(() => {
              if (this.errNum > 0)
                this.$message({
                  type: 'warning',
                  message: this.errNum + '张图片上传失败',
                })
              this.$notify.success({ title: '添加成功', duration: 2000 })
              this.submitLoadingStatus = false
              this.hidePopups()
              if (this.currentData.inType === 'list') {
                this.$parent.getTableList()
              } else {
                this.$parent.getDetailPhotos()
              }
            })
            .catch(() => {
              this.submitLoadingStatus = false
            })
          // }
        }
      })
    },
    hidePopups() {
      this.cancel = true
      this.$emit('hidePopups')
      this.$refs.form.resetFields()
      this.ossClient.cancel()
      Object.assign(this.$data.form, this.$options.data().form)
    },
    imageUrlToBase64(url) {
      return new Promise((resolve) => {
        const image = new Image() // 解决跨域问题
        image.setAttribute('crossOrigin', 'anonymous')
        const imageUrl = url
        image.src = imageUrl
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          const quality = 0.8 // 这里的dataurl就是base64类型
          const dataURL = canvas.toDataURL('image/jpeg', quality) // 使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
          resolve(dataURL)
        }
      })
      // 一定要设置为let，不然图片不显示
    },
    base64ToFile(urlData, fileName) {
      const arr = urlData.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, {
        type: mime,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-photo-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }

  ::v-deep .el-dialog__body {
    padding: 24px 40px 16px 40px;
  }

  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }

  ::v-deep .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ::v-deep.el-upload {
    display: block;
  }

  ::v-deep.el-upload-dragger {
    width: 100%;
  }

  ::v-deep .el-upload__tip {
    margin: 6px 0;
  }

  ::v-deep .el-dialog__body {
    padding: 30px 20px 0 20px;
  }

  ::v-deep .el-dialog__title {
    color: #1890ff;
    font-weight: 500;
    font-size: 16px;
  }

  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
}
</style>
