<template>
  <el-dialog custom-class="myVideo" :visible.sync="dialogVisible" :append-to-body="true" @close="cancel">
    <video v-if="dialogVisible" style="max-width: 800px; max-height: 600px" preload="auto" align="center" controls="true" width="100%" autoplay="muted">
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </el-dialog>
</template>
<script>
import 'video.js/dist/video-js.css'

export default {
  name: 'MyVideo',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
      videoUrl: '',
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val
      if (val) {
        this.videoUrl = this.url.split('?')[0]
      } else {
        this.videoUrl = ''
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep.el-dialog__body {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
